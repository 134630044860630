<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">企业文化</div>
        </HeadContent>
        <!--    核心价值观s-->
        <div class="hxjzg">
            <div style="width: 1200px;margin: 0 auto;">
                <div class="mtitle">核心价值观</div>
            </div>
            <div class="hxjzg-move">
                <div>
                    <img src="./images/culture/蒙版组 159.png" alt="">
                    <span>共享</span>
                </div>
                <div>
                    <img src="./images/culture/蒙版组 163.png" alt="">
                    <span>专业</span>
                </div>
                <div>
                    <img src="./images/culture/蒙版组 161.png" alt="">
                    <span>正直</span>
                </div>
                <div>
                    <img src="./images/culture/蒙版组 162.png" alt="">
                    <span>担当</span>
                </div>
            </div>
        </div>
        <!--    核心价值观e-->

        <!--    服务理念s-->
        <div class="fwln">
            <div class="fwln-c">
                <div class="fwln-c-l">
                    <img src="./images/culture/蒙版组 166.png" alt="">
                    <img src="./images/culture/蒙版组 165.png" alt="">
                    <img :src="wuxinList[wuxinAc].src" alt="">
                </div>
                <div class="fwln-c-r">
                    <div class="mtitle">服务理念</div>
                    <div>
                        以五心服务<br>
                        为理念
                    </div>
                    <div>
                        {{ wuxinList[wuxinAc].txt }}
                    </div>
                    <gk-arrow @rFn="wuRfn" @lFn="wuLfn"></gk-arrow>
                </div>
            </div>
        </div>
        <!--    服务理念e-->

        <!--    工作理念s-->
        <div class="gzln">
            <div class="mtitle">工作理念</div>
            <div class="gzln-c">
                <div class="gzln-col">
                    <img src="./images/culture/组 566.png" alt="">
                    <div>以人为本</div>
                    <div>
                        具备cma等多项国内外资质的<br>
                        第三方检测机构
                    </div>
                </div>
                <div class="gzln-col">
                    <img src="./images/culture/组 567.png" alt="">
                    <div>以客为尊</div>
                    <div>
                        高规格实验室标准<br>
                        工程师一对一服务
                    </div>
                </div>
                <div class="gzln-col">
                    <img src="./images/culture/组 569.png" alt="">
                    <div>不断创新</div>
                    <div>
                        从原料到上市<br>
                        食品农产品业覆盖领域广
                    </div>
                </div>
                <div class="gzln-col">
                    <img src="./images/culture/组 570.png" alt="">
                    <div>勇争第一</div>
                    <div>
                        一站式服务<br>
                        整体解决方案
                    </div>
                </div>
            </div>
        </div>
        <!--    工作理念e-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                wuxinAc: 0,
                wuxinList: [
                    {
                        txt: "服务称心",
                        src: require('./images/culture/蒙版组 164.png')
                    },
                    {
                        txt: "检测精心",
                        src: require('./images/culture/蒙版组 282.png')
                    },
                    {
                        txt: "质量放心",
                        src: require('./images/culture/蒙版组 261.png')
                    },
                    {
                        txt: "用我们真心",
                        src: require('./images/culture/蒙版组 264.png')
                    },
                    {
                        txt: "保客户称心",
                        src: require('./images/culture/蒙版组 267.png')
                    }
                ]
            }
        },
        methods: {
            wuRfn() {
                this.wuxinAc += 1
                if (this.wuxinAc > this.wuxinList.length - 1) {
                    this.wuxinAc = 0
                }
            },
            wuLfn() {
                this.wuxinAc -= 1
                if (this.wuxinAc < 0) {
                    this.wuxinAc = this.wuxinList.length - 1
                }
            }
        }
    }
</script>

<style lang="scss">
    //核心价值观s
    .hxjzg {
        padding: 90px 0;

        .hxjzg-move {
            margin-top: 40px;
            display: flex;
            transform: translateX(-140px);

            div {
                position: relative;
                width: 505px;
                height: 365px;
                flex-shrink: 0;
                margin-right: 52px;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 135px;
                    font-size: 30px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 36px;
                    color: rgba(#FFFFFF, 0.83);
                    letter-spacing: 6px;
                    background: rgba(#1320E8, 0.26);
                    @include flexCenter;
                }
            }
        }
    }

    //核心价值观e

    //服务理念s
    .fwln {
        padding: 150px 0;
        background: #FBFBFB;
        .fwln-c {
            @include page1200;
            display: flex;
            .fwln-c-l {
                position: relative;
                display: flex;
                align-items: center;
                width: 614px;
                height: 410px;
                margin-right: 122px;
                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                img:nth-child(1) {
                    left: 0;
                    width: 343px;
                    height: 256px;
                }
                img:nth-child(2) {
                    left: 30px;
                    width: 459px;
                    height: 344px;
                }
                img:nth-child(3) {
                    left: 66px;
                    width: 548px;
                    height: 410px;
                }
            }

            .fwln-c-r {
                height: 406px;
                div:nth-child(2) {
                    margin-top: 40px;
                    margin-bottom: 8px;
                    font-size: 40px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #606060;
                }
                div:nth-child(3) {
                    margin-bottom: 90px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #606060;
                }

            }

        }
    }

    //服务理念e

    //工作理念s
    .gzln {
        @include page1200;
        padding: 90px;
        .gzln-c {
            padding-top: 40px;
            display: flex;
            .gzln-col {
                width: 201px;
                height: 257px;
                margin-right: 122px;
                background: #FFFFFF;
                flex-shrink: 0;
                @include flexCenter;
                flex-direction: column;
                transition: all linear .3s;
                img {
                    transition: all linear .3s;
                }

                img {
                    width: 80px;
                    height: 80px;
                }
                div:nth-child(2) {
                    margin-top: 28px;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #606060;
                }

                div:nth-child(3) {
                    font-size: 10px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #888888;
                    text-align: center;
                }

                &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
                    img {
                        width: 98px;
                        height: 98px;
                    }
                    div:nth-child(2) {
                        font-size: 24px;
                        font-weight: 600;
                        color: #333333;
                    }

                    div:nth-child(3) {
                        font-size: 15px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #888888;
                    }
                }
            }
        }
    }

    //工作理念e
</style>
